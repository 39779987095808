<!--  -->
<template>
  <div class="filter">
      <h6 @click="back"><i class="el-icon-arrow-left"></i>Back</h6>
      <p>Filter type</p>
      <el-radio-group v-model="radio" @change="radioclick">
        <ul>
            <li v-for="(item,index) in filtertype" :key="index" >
                <el-radio :label="item"  >{{item}}</el-radio>
            </li>
        </ul>
    </el-radio-group>
  </div>
</template>

<script>
export default {
  data () {
    return {
        radio:"",
        filtertype:[
            'Lead properties',
            'Contact properties',
            'Opportunity properties',
            'Activity properties',
            'Case properties'
        ]
    }
  },
  methods:{
      back(){
          this.$emit('back',false);
      },
      radioclick(){
          this.$emit('filtercheck',this.radio,false);
      }
  }
}
</script>

<style lang='scss' scoped >
.filter{
    width: 100%;
    h6{
        width: 100%;
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #006DCC;
        letter-spacing: 0;
        margin-top: 18px;
        cursor: pointer;
        i{
            padding-left: 10px;
            padding-right: 10px;
        }
    }
    p{
        font-family: SourceHanSansCN-Medium;
        font-size: 14px;
        color: #080707;
        letter-spacing: 0;
        font-weight: bolder;
        padding-left: 10px;
        margin-top: 10px;
    }
    ul{
        width: 100%;
        li{
            margin-left: 10px;
            font-family: Helvetica;
            font-size: 12px;
            color: #080707;
            letter-spacing: 0;
            line-height: 32px;
        }
    }
}
</style>
