<!--  -->
<template>
  <div class="particulars">
    <div class="parthead">
      <p class="back" @click="goBack">
        <i class="el-icon-arrow-left"></i>Back to lists
      </p>
      <header>
        <div class="headleft" ref="headleft">
          <!-- <input type="text" :class="namechange ? 'inputnamechange' : 'inputnone'"  v-model="inputname" :disabled="namechange" > -->
          <input v-model="inputname" v-show="namechange" />
          <p>{{ namechange ? "" : inputname }}</p>
          <svg
            class="icon"
            aria-hidden="true"
            style="width: 15px; height: 15px"
            @click="namechange = !namechange"
          >
            <use href="#icon-editPens"></use>
          </svg>
        </div>
        <div class="headright">
          <el-input
            class="search-text"
            v-model="searchValue"
            placeholder="Search for forms"
            @keyup.enter.native="searchList"
            suffix-icon="el-icon-search"
          ></el-input>
          <el-dropdown trigger="click" popper-class="socialmondrawer">
            <span class="el-dropdown-link">
              Actions<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="item in actions" :key="item"
                >{{ item }}
                <svg
                  v-show="item == 'View on Linkedln'"
                  class="icon"
                  style="width: 20px; height: 20px"
                  aria-hidden="true"
                >
                  <use href="#icon-share"></use>
                </svg>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <button class="savebutton">Save</button>
        </div>
      </header>
      <p class="estimation">Estimation: {{ contactnum }} contact</p>
    </div>
    <div class="particulconent">
      <div class="conentleft">
        <div class="conentlefnav">
          <el-select v-model="Daterange" v-if="acsta">
            <el-option
              v-for="item in Dateranges"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
          <span v-if="!acsta">{{ Daterange }}</span>
          <svg
            class="icon"
            style="width: 20px; height: 20px"
            aria-hidden="true"
          >
            <use href="#icon-prompt"></use>
          </svg>
        </div>
        <div class="orand" v-if="orandshow">
          <div
            class="clonedelete"
            v-for="(item, index) in arrydata"
            :key="index"
            v-show="arrydata.length > 0"
          >
            <div class="orandhead" v-show="item.length > 0">
              <div class="orandheadleft">
                <b v-show="index > 0">OR</b>
              </div>
              <p>
                <span @click="clone(index)">Clone</span
                ><span @click="deleted(index)">Delete</span>
              </p>
            </div>
            <div
              class="or"
              v-for="(commond, num) in item"
              :key="num"
              v-show="item.length > 0"
            >
              <div
                class="and"
                v-for="(val, count) in commond"
                :key="count"
                v-show="commond.length > 0"
              >
                <p>Form submission</p>
                <ul v-for="(deserve, add) in val" :key="add">
                  <p v-if="add > 0">and</p>
                  <li
                    @mouseenter="mousenter(index, num, count, add)"
                    @mouseleave="mousleave(index, num, count, add)"
                  >
                    <span
                      class="bluespan"
                      @click="addserve(deserve, index, num, count, add)"
                    >
                      {{ deserve.fill }}</span
                    >
                    <span class="span">{{ deserve.from }}</span>
                    <span
                      v-for="(deto, dein) in deserve.to"
                      :key="dein"
                      class="bspan"
                      @click="addserve(deserve, index, num, count, add)"
                    >
                      <span v-if="dein > 0" class="span">or</span>
                      {{ deto }}</span
                    >
                    <div class="img" v-if="deserve.show">
                      <svg
                        class="icon"
                        style="width: 20px; height: 20px"
                        aria-hidden="true"
                        @click="deletedata(index, num, count, add)"
                      >
                        <use href="#icon-delete"></use>
                      </svg>
                    </div>
                  </li>
                </ul>
                <button
                  class="andbutton"
                  @click="addclick(index, num, count, val.length)"
                >
                  AND
                </button>
              </div>
              <button
                class="andbutton"
                @click="addsecondclick(index, num, commond.length, null)"
              >
                AND
              </button>
            </div>
          </div>
          <button
            class="andbutton"
            @click="orclick(arrydata.length, null, null, null)"
          >
            OR
          </button>
        </div>
        <Filter-type
          v-if="filtertypeshow"
          @back="filterback"
          @filtercheck="filtercheck"
        >
        </Filter-type>
        <Search
          v-if="searchshow"
          :filterval="filterval"
          @searchback="searchback"
          @searchcheck="searchcheck"
        >
        </Search>
        <See-datails
          v-if="seedatailshow"
          :searchval="searchval"
          :seedatafrom="seedatafrom"
          :seedatato="seedatato"
          :checkboxval="checkboxval"
          @seedataback="seedataback"
          @propsadd="propsadd"
          @seedatashow="seedatashow"
          @checkboxed="checkboxed"
        >
        </See-datails>
      </div>
      <div class="conentright">
        <Table-list
          :tableAttr="tableAttr"
          :tableData="tableData"
          :tableheight="tableheight"
          :selectvalue="selectvalue"
          :buttonlist="buttonlist"
          :pageshow="pageshow"
        >
        </Table-list>
      </div>
    </div>
  </div>
</template>

<script>
import TableList from "../components/listTable.vue";
import FilterType from "@/views/lists/particulars/components/filtertype.vue";
import Search from "@/views/lists/particulars/components/search.vue";
import SeeDatails from "@/views/lists/particulars/components/seedatails.vue";
export default {
  components: {
    TableList,
    FilterType,
    Search,
    SeeDatails,
  },
  data() {
    return {
      inputname: "demo test (cloned)", //name
      namechange: false, //修改name
      actions: ["Clone", "Delete", "View on Linkedln"],
      searchValue: "",
      contactnum: 1, //Estimation数量
      Daterange: "Active list",
      Dateranges: ["Active list", "Static list"],
      acsta: false, //选择静态动态
      arrydata: [],
      //右侧表格数据
      tableAttr: [
        {
          aa: "NAME",
          bb: "name",
          cc: false,
          dd: true,
          ee: "A",
          width: "300px",
        },
        { aa: "EMAIL", bb: "email", cc: false, dd: true, ee: "B", width: "" },
        {
          aa: "PHONE NUMBER",
          bb: "phonenumber",
          cc: false,
          dd: true,
          ee: "B",
          width: "",
        },
        {
          aa: "CREATOR",
          bb: "creator",
          cc: false,
          dd: true,
          ee: "B",
          width: "",
        },
      ],
      tableheight: "300px", //表格高度
      buttonlist: ["aa", "vv", "Actions"],
      tableData: [
        {
          img: '#icon-in',
          name: "Asddd",
          vshow: false,
          email: "123456@qq.com",
          phonenumber: "-",
          creator: "",
          optionshow: false,
        },
        {
          img: '#icon-in',
          name: "dvgdnhjutm",
          vshow: false,
          email: "ssssss@qq.com",
          phonenumber: "-",
          creator: "",
          optionshow: false,
        },
        {
          img: '#icon-in',
          name: "wwwwww",
          vshow: false,
          email: "09754@qq.com",
          phonenumber: "1359344555455",
          creator: "",
          optionshow: false,
        },
        {
          img: '#icon-in',
          name: "wwwwww",
          vshow: false,
          email: "4568789@qq.com",
          phonenumber: "13034456788",
          creator: "",
          optionshow: false,
        },
      ],
      selectvalue: {
        selectvalue1: ["View", "Export submission", "Create list of contacts"],
        selectvalue2: ["Move to folder", "Delete"],
      },
      pageshow: true,
      //左侧数据
      orandshow: true, //or页面显示
      filtertypeshow: false, //filter type 页面显示
      filterval: "", //filter type 页面选择的过滤类型
      searchshow: false, //search页面显示
      searchval: "", //search 页面选中的值
      seedatailshow: false, //see datails 页面显示
      addfirst: null,
      addsecond: null,
      addthird: null,
      addfouth: null,
      seeback: false,
      seedatafrom: "",
      seedatato: [],
      addserveshow: false,
      checkboxval: [],
    };
  },
  beforeRouteEnter(to, from, next) {
    
    next((vm) => {
      if (from.fullPath === "/lists/createLists") {
        vm.arrydata = [];
        vm.orandshow = false;
        vm.filtertypeshow = true;
        vm.searchshow = false;
        vm.seedatailshow = false;
        vm.addfirst = 0;
        vm.addsecond = 0;
        vm.addthird = 0;
        vm.addfouth = 0;
      }
    });
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    //搜索
    searchList() { },
    clone(index) {
      this.arrydata.push(JSON.parse(JSON.stringify(this.arrydata[index])));
      this.arrydata = [...this.arrydata];
    },
    deleted(index) {
      this.arrydata.splice(index, 1);
    },
    mousenter(index, num, count, add) {
      this.arrydata[index][num][count][add].show = true;
      this.arrydata = [...this.arrydata];
    },
    mousleave(index, num, count, add) {
      this.arrydata[index][num][count][add].show = false;
      this.arrydata = [...this.arrydata];
    },
    //点击or
    orclick(first, second, third, fouth) {
      this.orandshow = false;
      this.filtertypeshow = true;
      this.searchshow = false;
      this.seedatailshow = false;
      this.addfirst = first;
      this.addsecond = second;
      this.addthird = third;
      this.addfouth = fouth;
      this.seedatafrom = "";
      this.seedatato = [];
    },
    //filter type 页面返回
    filterback(show) {
      this.orandshow = !show;
      this.filtertypeshow = show;
      this.searchshow = show;
      this.seedatailshow = show;
    },
    //filter type 选择类型后返回
    filtercheck(val, show) {
      this.filterval = val;
      this.orandshow = show;
      this.filtertypeshow = show;
      this.searchshow = !show;
      this.seedatailshow = show;
    },
    //search 页面返回
    searchback(show) {
      if (this.seeback) {
        this.orandshow = !show;
        this.filtertypeshow = show;
        this.searchshow = show;
        this.seedatailshow = show;
        this.seeback = false;
      } else {
        this.orandshow = show;
        this.filtertypeshow = !show;
        this.searchshow = show;
        this.seedatailshow = show;
      }
    },
    //search  选中后返回
    searchcheck(show, val) {
      this.orandshow = show;
      this.filtertypeshow = show;
      this.searchshow = show;
      this.seedatailshow = !show;
      this.searchval = val;
    },
    //see data back页面 返回
    seedataback(show) {
      if (this.addserveshow) {
        this.orandshow = !show;
        this.filtertypeshow = show;
        this.searchshow = show;
        this.seedatailshow = show;
        this.addserveshow = false;
      } else {
        this.orandshow = show;
        this.filtertypeshow = show;
        this.searchshow = !show;
        this.seedatailshow = show;
      }
    },
    //see data 页面 返回
    seedatashow(show) {
      this.orandshow = !show;
      this.filtertypeshow = show;
      this.searchshow = show;
      this.seedatailshow = show;
    },
    //see data 页面 返回数据
    propsadd(item, val, data) {
      let arr = [];
      if (val) {
        if (val instanceof Array) {
          for (var i = 0; i < val.length; i++) {
            arr.push(val[i]);
          }
        } else {
          arr.push(val);
        }
      }
      if (data) {
        arr.push(data);
      }
      let obj = {
        fill: this.searchval,
        from: item,
        to: arr,
        show: false,
      };

      if (this.addfouth || this.addfouth == 0) {
        this.arrydata[this.addfirst][this.addsecond][this.addthird][
          this.addfouth
        ] = obj;
      } else {
        if (this.addthird || this.addthird == 0) {
          this.arrydata[this.addfirst][this.addsecond][this.addthird] = [obj];
        } else {
          if (this.addsecond || this.addsecond == 0) {
            this.arrydata[this.addfirst][this.addsecond] = [[obj]];
          } else {
            if (this.addfirst || this.addfirst == 0) {
              this.arrydata[this.addfirst] = [[[obj]]];
            } else {
              this.arrydata = [[[[obj]]]];
            }
          }
        }
      }
    },
    //添加 第四层
    addclick(index, num, count, length) {
      this.addfirst = index;
      this.addsecond = num;
      this.addthird = count;
      this.addfouth = length;
      this.orandshow = false;
      this.filtertypeshow = false;
      this.searchshow = true;
      this.seedatailshow = false;
      this.seeback = true;
      this.seedatafrom = "";
      this.seedatato = [];
    },
    //添加第三层
    addsecondclick(index, num, length, cont) {
      this.addfirst = index;
      this.addsecond = num;
      this.addthird = length;
      this.addfouth = cont;
      this.orandshow = false;
      this.filtertypeshow = true;
      this.searchshow = false;
      this.seedatailshow = false;
      this.seedatafrom = "";
      this.seedatato = [];
    },

    addserve(deserve, first, second, third, fouth) {
      this.searchval = deserve.fill;
      this.seedatafrom = deserve.from;
      this.seedatato = deserve.to;
      this.orandshow = false;
      this.filtertypeshow = false;
      this.searchshow = false;
      this.seedatailshow = true;
      this.addserveshow = true;
      deserve.show = false;
      this.addfirst = first;
      this.addsecond = second;
      this.addthird = third;
      this.addfouth = fouth;
    },
    //删除数据
    deletedata(index, num, count, add) {
      this.arrydata[index][num][count].splice(add, 1);
      if (this.arrydata[index][num][count].length < 1) {
        this.arrydata[index][num].splice(count, 1);
      }
      if (this.arrydata[index][num].length < 1) {
        this.arrydata[index].splice(num, 1);
      }
      if (this.arrydata[index].length < 1) {
        this.arrydata.splice(index, 1);
      }
    },
    checkboxed(val) {
      this.checkboxval = val;
    },
    clickFn(e) {
      // 记得在.select-box那边加上ref="selectBox"
      let headleft = this.$refs.headleft;
      // 重点来了：selectBox里是否包含点击的元素，不包含点击的元素就隐藏面板
      if (headleft) {
        if (!headleft.contains(e.target)) {
          this.namechange = false;
        }
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.clickFn);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.clickFn);
  },
  created() {
    this.inputname = this.$route.params.id;
    this.Daterange = this.$route.params.title;
    this.acsta = this.$route.params.acsta;
  },
};
</script>

<style lang='scss' scoped >
.particulars {
  width: 98.5%;
  margin-left: 0.6%;
  margin-top: 11px;
  // margin-bottom: 10px;
  background: #ffffff;
  border: 1px solid #dddbda;
  border-radius: 3px;
  font-family: MicrosoftYaHei;
  letter-spacing: 0;
  min-height: 86vh;
  .back {
    font-size: 14px;
    color: #006dcc;
    margin-top: 15px;
    margin-left: 10px;
    cursor: pointer;
    margin-bottom: 11px;
    i {
      padding-right: 12px;
    }
  }
  header {
    display: flex;
    justify-content: space-between;
    .headleft {
      margin-left: 10px;
      vertical-align: middle;
      img {
        cursor: pointer;
        float: left;
        margin-top: 5px;
      }
      p {
        font-family: SourceHanSansCN-Bold;
        font-size: 18px;
        color: #080707;
        font-weight: bolder;
        max-width: 150px;
        margin-right: 10px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        height: 30px;
        float: left;
      }
      input {
        height: 30px;
        border: 1px solid #888888;
        font-family: SourceHanSansCN-Bold;
        font-size: 18px;
        color: #080707;
        font-weight: bolder;
        background: #ffffff;
        padding: 5px 5px;
        outline: none;
        max-width: 150px;
        margin-right: 10px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        float: left;
      }
    }
    .headright {
      .search-text {
        width: 337px;
        height: 34px;
        margin-right: 10px;
      }
      ::v-deep .el-dropdown {
        border: 1px solid #006dcc;
        outline: none;
        padding: 9px 28px;
        border-radius: 3px;
        margin-right: 10px;
        .el-dropdown-link .el-dropdown-selfdefine {
          outline: none;
        }
      }
      .savebutton {
        background: #006dcc;
        border-radius: 3px;
        border: none;
        outline: none;
        font-family: MicrosoftYaHei;
        font-size: 12px;
        color: #ffffff;
        letter-spacing: 0;
        text-align: center;
        width: 102px;
        height: 38px;
        margin-right: 10px;
      }
    }
  }
  .estimation {
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #888888;
    letter-spacing: 0;
    margin-top: 5px;
    margin-bottom: 12px;
    margin-left: 10px;
  }
}
.particulconent {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .conentleft {
    width: 24.4%;
    .conentlefnav {
      background: #fafaf9;
      border-top: 1px solid #dddbda;
      border-bottom: 1px solid #dddbda;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        font-family: SourceHanSansCN-Medium;
        font-size: 16px;
        color: #080707;
        letter-spacing: 0;
        font-weight: bolder;
        padding-left: 10px;
      }
      ::v-deep .el-select {
        width: 90%;
        background: #ffffff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 5px;
        outline: none;
        .el-input__inner {
          border: none;
          height: 32px;
          font-family: SourceHanSansCN-Medium;
          font-size: 16px;
          color: #080707;
          letter-spacing: 0;
          font-weight: bolder;
        }
      }
      img {
        margin-right: 2.8%;
        width: 12px;
        height: 12px;
      }
    }
    .clonedelete {
      width: 100%;
      .orandhead {
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
        .orandheadleft {
          b {
            padding-left: 15px;
          }
        }
      }

      b {
        font-family: SourceHanSansCN-Bold;
        font-size: 14px;
        color: #080707;
        padding-left: 2.8%;
      }
      p {
        span {
          display: inline-block;
          font-family: MicrosoftYaHei;
          font-size: 12px;
          color: #006dcc;
          letter-spacing: 0;
          cursor: pointer;
          margin-right: 10px;
        }
      }
    }
    .andbutton {
      border: 1px solid #dddbda;
      border-radius: 3px;
      font-family: MicrosoftYaHei;
      font-size: 12px;
      color: #006dcc;
      letter-spacing: 0;
      text-align: center;
      background: #ffffff;
      padding: 7px 18px;
      outline: none;
      margin-left: 2.8%;
      cursor: pointer;
      margin-top: 12px;
      margin-bottom: 12px;
    }
    .or {
      width: 93.8%;
      border: 1px solid #dddbda;
      border-radius: 3px;
      margin-left: 2.8%;
      .and {
        width: 93.8%;
        border: 1px solid #dddbda;
        border-radius: 3px;
        margin-left: 2.8%;
        margin-top: 10px;
        font-family: MicrosoftYaHei;
        font-size: 12px;
        color: #080707;
        letter-spacing: 0;
        p {
          padding-left: 10px;
          padding-top: 10px;
        }
        .bluespan {
          font-family: MicrosoftYaHei;
          font-size: 14px;
          color: #006dcc;
          font-weight: bold;
        }
        .bluespan:hover {
          border-bottom: 1px solid #006dcc;
          cursor: pointer;
        }
        .span {
          font-family: MicrosoftYaHei;
          font-size: 12px;
          color: #080707;
          letter-spacing: 0;
        }
        .bspan {
          font-family: MicrosoftYaHei;
          font-size: 14px;
          color: #080707;
          font-weight: bold;
          cursor: pointer;
          .span {
            font-weight: normal;
            padding-left: 5px;
            cursor: default;
          }
        }
        ul {
          width: 100%;
          li {
            width: 100%;
            line-height: 30px;
            padding-left: 10px;
            position: relative;
            span {
              color: #006dcc;
            }
            .img {
              position: absolute;
              right: 0;
              top: 0;
              height: 100%;
              width: 40px;
              background: #dddbda;
              z-index: 99;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              img {
                width: 16px;
                height: 16px;
              }
            }
          }
          li:hover {
            background: #dedcda;
          }
        }
      }
    }
  }
  .conentright {
    width: 75.6%;
  }
}
</style>
