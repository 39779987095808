<!--  -->
<template>
  <div class="seedatatils">
    <h6 @click="back"><i class="el-icon-arrow-left"></i>Back</h6>
    <div class="seedatalist">
      <p class="seep">{{ searchval }}</p>
      <div class="more">
        <div class="span">
          <span>{{ explainsect }}</span>
        </div>
        <p v-if="explain.length > 200" @click="moreclick">
          <i :class="moreshow ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"></i
          ><span v-show="moreshow">See more</span
          ><span v-show="!moreshow">See less</span>
        </p>
      </div>
      <h5>
        <span>See details </span>
        <svg class="icon" style="width: 20px; height: 20px" aria-hidden="true">
          <use href="#icon-share"></use>
        </svg>
      </h5>
      <div v-for="(val, index) in lis" :key="index" class="radio">
        <p class="radiop">
          <input
            type="radio"
            name="radio"
            :value="val.radio"
            v-model="radio"
            @click="radio = val.radio"
            class="elradio"
          />{{ val.radio }}
        </p>
        <!-- 添加标签 -->
        <div class="select" v-show="radio == val.radio && val.selectshow">
          <span v-for="(ite, num) in selected" :Key="num" v-show="selected"
            >{{ ite }} <i class="el-icon-error" @click="delet(num)"></i
          ></span>
          <input
            type="text"
            v-model="value"
            ref="selectinput"
            :placeholder="!selected.length ? 'Add Values' : '|'"
            @focus="butshow = true"
            class="inputselect"
          />
        </div>
        <el-button
          class="but"
          :disabled="!value"
          @click="butclick(value)"
          v-show="butshow && radio == val.radio"
        >
          <p v-show="!value">Type to add a value to this filter</p>
          <p v-show="value" class="butp">Add value "{{ value }} "</p>
        </el-button>
        <!-- 开始日期     -->
        <el-date-picker
          v-show="radio == val.radio && val.startdateshow"
          v-model="startdate"
          type="date"
          placeholder="MM/DD/YYYY"
        >
        </el-date-picker>
        <!-- 结束日期 -->
        <div v-show="radio == val.radio && val.enddateshow" class="startend">
          <h3>and</h3>
          <el-date-picker
            v-model="enddate"
            type="date"
            placeholder="MM/DD/YYYY"
          >
          </el-date-picker>
        </div>
        <!-- ago时间 -->
        <div class="ago" v-show="radio == val.radio && val.agoshow">
          <input
            type="text"
            v-model="dayagoone"
            v-show="val.options.length < 1"
          />
          <span v-show="val.options.length < 1">days ago</span>
          <input
            type="text"
            v-model="dayagotwo"
            v-show="val.options.length >= 1"
          />
          <el-dropdown
            @command="handCommand"
            v-show="val.options.length >= 1"
            placement="bottom"
          >
            <span class="el-dropdown-link">
              <span>{{ dayagos }}</span>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="(range, indexs) in val.options"
                :key="indexs"
                :command="range"
                >{{ range }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <!-- select -->
        <div v-show="radio == val.radio && val.optionshow" class="selectsearch">
          <p class="el-select" @click="selectclick" ref="elselected">
            <span>{{ radioValue }}</span
            ><i
              :class="
                radiosearchshow ? 'el-icon-arrow-down' : 'el-icon-arrow-up'
              "
            ></i>
          </p>
          <div v-if="selectedshow" class="el-options">
            <div class="inputop">
              <el-input
                class="search-text"
                v-model="searchValue"
                placeholder="Search"
                @keyup.enter.native="searchList"
                suffix-icon="el-icon-search"
              ></el-input>
            </div>
            <ul class="inputul">
              <li
                v-for="(item, num) in val.types"
                :key="num"
                @click="radioliclick(item.title)"
              >
                <h4>{{ item.title }}</h4>
                <span style="word-wrap: break-word">{{ item.conent }}</span>
              </li>
            </ul>
            <div></div>
          </div>
        </div>
        <!-- 多选框 -->
        <ul
          v-if="!butshow && val.checkbox.length > 0 && radio == val.radio"
          class="elcheckbox"
        >
          <li v-for="(checkval, checkindex) in val.checkbox" :key="checkindex">
            <input
              type="checkbox"
              name="checkbox"
              v-model="checkval.checkedselect"
            />
            <p>{{ checkval.val }}</p>
          </li>
        </ul>
        <!-- <el-checkbox-group v-model="checkList"  v-if="!butshow&&val.checkbox.length>0 &&radio == val.radio">
                <ul> 
                    <li v-for="(checkval,checkindex) in val.checkbox" :key="checkindex"> <el-checkbox :label="checkval">{{checkval}}</el-checkbox> </li>
                </ul>
            </el-checkbox-group>   -->
      </div>
    </div>
    <el-button
      :disabled="buttondisabled"
      :type="buttondisabled ? '' : 'primary'"
      @click="applyclick"
      >Apply filter</el-button
    >
  </div>
</template>

<script>
export default {
  props: {
    searchval: {
      // search 选中
      type: String,
      default: "",
    },
    seedatafrom: {
      type: String,
      default: "",
    },
    seedatato: {
      type: Array,
      default:() => [],
    },
    checkboxval: {
      type: Array,
      default:() => [],
    },
  },
  data() {
    return {
      seedatatoD:this.seedatato,
      explainsect: "",
      explain:
        "This UTM parameter shows which marketing campaign (e.g. a specific email) referred an associated contact to the meetings tool for their most recent booking. This property is only populated when you add tracking parameters to your meeting link.",
      moreshow: true, //更多的显示隐藏
      radio: "", //单选的内容
      selected: [], //标签数组
      value: "", //添加的标签初始值
      lis: [
        {
          radio: "is equal to any of",
          selectshow: true,
          checkbox: [],
          startdateshow: false,
          enddateshow: false,
          agoshow: false,
          options: [],
          optionshow: false,
          types: [],
        },
        {
          radio: "is not equal to any of",
          selectshow: true,
          checkbox: [
            {
              checkedselect: false,
              val: `Include records where "Campaign of last 'booking in meetings tool' is empty`,
            },
          ],
          startdateshow: false,
          enddateshow: false,
          agoshow: false,
          options: [],
          optionshow: false,
          types: [],
        },
        {
          radio: "contains any of ",
          selectshow: false,
          checkbox: [],
          startdateshow: true,
          enddateshow: false,
          agoshow: false,
          options: [],
          optionshow: false,
          types: [],
        },
        {
          radio: "contains any of a",
          selectshow: false,
          checkbox: [],
          startdateshow: true,
          enddateshow: true,
          agoshow: false,
          options: [],
          optionshow: false,
          types: [],
        },
        {
          radio: "contains any of b",
          selectshow: false,
          checkbox: [],
          startdateshow: false,
          enddateshow: false,
          agoshow: true,
          options: [],
          optionshow: false,
          types: [],
        },
        {
          radio: "contains any of c",
          selectshow: false,
          checkbox: [],
          startdateshow: false,
          enddateshow: false,
          agoshow: true,
          options: ["days ago", "days from now"],
          optionshow: false,
          types: [],
        },
        {
          radio: "contains any of d",
          selectshow: false,
          checkbox: [],
          startdateshow: false,
          enddateshow: false,
          agoshow: false,
          options: [],
          optionshow: true,
          types: [
            {
              title: "Targin Account",
              conent: "sfcvbgtnyumki,i,i,.iu,.oiu.oiu.poi",
            },
            {
              title: "Account",
              conent: "sfcvbgtnyumki,i,i,.iu,.oiu.oiu.poi",
            },
            {
              title: "Count",
              conent: "sfcvbgtnyumki,i,i,.iu,.oiu.oiu.poi",
            },
            {
              title: "Bount",
              conent: "sfcvbgtnyumki,i,i,.iu,.oiu.oiu.poi",
            },
            {
              title: "Eunt",
              conent: "sfcvbgtnyumki,i,i,.iu,.oiu.oiu.poi",
            },
            {
              title: "Dunt",
              conent:
                "dgrghrej jgkjrwgjk jwgrhkjrwh jrhrwkj rgjhkjrwhhgw jrhgwkjrh jghwrkjh jkwhgwkjreh jhgkjewq jekjgheqf mj",
            },
          ],
        },
        {
          radio: "contains any of e",
          selectshow: false,
          checkbox: [],
          startdateshow: false,
          enddateshow: false,
          agoshow: false,
          options: [],
          optionshow: false,
          types: [],
        },
      ],
      butshow: false,
      checkList: [], //多选框选中的
      startdate: "", //开始时间
      enddate: "", //结束时间
      dayagoone: null, //时间daya ago 结尾的
      dayagotwo: null, //时间可选择的
      dayagos: "days ago",
      // value: "", //选择类型
      radioValue: "", //select的内容
      searchValue: "", //search的内容
      selectedshow: false, //search框使我显示隐藏
      radiosearchshow: true, //serch框图标转换
      // buttondisabled:true,//apply filter按钮的禁用
    };
  },
  methods: {
    back() {
      this.radio = ""; //单选的内容
      this.selected = []; //标签数组
      this.value = ""; //添加的标签初始值
      this.startdate = ""; //开始时间
      this.enddate = ""; //结束时间
      this.dayagoone = null; //时间daya ago 结尾的
      this.dayagotwo = null; //时间可选择的
      this.radioValue = ""; //select的内容
      this.searchValue = ""; //search的内容
      this.$emit("seedataback", false);
    },
    moreclick() {
      this.moreshow = !this.moreshow;
      if (this.moreshow == false) {
        this.explainsect = this.explain;
      } else {
        this.explainsect = this.explain.slice(0, 200);
      }
    },
    butclick(value) {
      this.selected.push(value);
      this.value = "";
      // this.$refs.selectinput.focus();
      this.butshow = true;
    },
    delet(index) {
      this.selected.splice(index, 1);
    },
    radiocheck() {
      this.butshow = false;
    },
    handCommand(command) {
      this.dayagos = command;
    },
    searchList() {},
    selectclick() {
      this.selectedshow = !this.selectedshow;
      this.radiosearchshow = !this.radiosearchshow;
    },
    radioliclick(item) {
      this.radioValue = item;
      this.selectedshow = false;
      this.radiosearchshow = true;
    },
    applyclick() {
      for (let i = 0; i < this.lis.length; i++) {
        if (this.radio == this.lis[i].radio) {
          if (
            this.lis[i].selectshow == true &&
            this.lis[i].checkbox.length < 1
          ) {
            this.$emit("propsadd", this.radio, this.selected);
          } else {
            if (
              this.lis[i].selectshow == true &&
              this.lis[i].checkbox.length > 0
            ) {
              for (var j = 0; j < this.lis[i].checkbox.length; j++) {
                if (this.lis[i].checkbox[j].checkedselect == true) {
                  this.$emit("propsadd", this.radio, this.selected, "empty");
                  this.checkList.push(j);
                  this.$emit("checkboxed", this.checkList);
                } else {
                  this.$emit("propsadd", this.radio, this.selected);
                }
              }
            } else {
              if (
                this.lis[i].startdateshow == true &&
                this.lis[i].enddateshow == false
              ) {
                this.$emit("propsadd", this.radio, this.startdate);
              } else {
                if (
                  this.lis[i].startdateshow == true &&
                  this.lis[i].enddateshow == true
                ) {
                  this.$emit(
                    "propsadd",
                    this.radio,
                    this.startdate,
                    this.enddate
                  );
                } else {
                  if (
                    this.lis[i].agoshow == true &&
                    this.lis[i].options.length < 1 &&
                    this.dayagoone
                  ) {
                    this.$emit(
                      "propsadd",
                      this.radio,
                      this.dayagoone + " " + "days ago"
                    );
                  } else {
                    if (
                      this.lis[i].agoshow == true &&
                      this.lis[i].options.length > 0 &&
                      this.dayagotwo
                    ) {
                      this.$emit(
                        "propsadd",
                        this.radio,
                        this.dayagotwo + " " + this.dayagos
                      );
                    } else {
                      if (this.lis[i].optionshow == true) {
                        this.$emit("propsadd", this.radio, this.radioValue);
                      } else {
                        this.$emit("propsadd", this.radio);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      this.$emit("seedatashow", false);
      this.radio = ""; //单选的内容
      this.selected = []; //标签数组
      this.value = ""; //添加的标签初始值
      this.startdate = ""; //开始时间
      this.enddate = ""; //结束时间
      this.dayagoone = null; //时间daya ago 结尾的
      this.dayagotwo = null; //时间可选择的
      this.radioValue = ""; //select的内容
      this.searchValue = ""; //search的内容
    },
    clickFn(e) {
      if (e.target.className == "inputselect") {
        this.butshow = true;
      } else {
        this.butshow = false;
      }
    }
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    buttondisabled() {
      for (let i = 0; i < this.lis.length; i++) {
        if (this.radio == this.lis[i].radio) {
          if (this.lis[i].selectshow == true && this.selected.length > 0) {
            return false;
          } else {
            if (this.lis[i].checkbox.length > 0 && this.checkList.length > 0) {
              return false;
            } else {
              if (this.lis[i].enddateshow == true && this.enddate) {
                if (this.lis[i].startdateshow == true && this.startdate) {
                  return false;
                }
              } else {
                if (
                  this.lis[i].startdateshow == true &&
                  this.startdate &&
                  this.lis[i].enddateshow == false
                ) {
                  return false;
                } else {
                  if (
                    this.lis[i].agoshow == true &&
                    this.dayagoone &&
                    this.lis[i].options.length < 1
                  ) {
                    return false;
                  } else {
                    if (
                      this.lis[i].agoshow == true &&
                      this.dayagotwo &&
                      this.lis[i].options.length > 0
                    ) {
                      return false;
                    } else {
                      if (
                        this.lis[i].optionshow == true &&
                        this.radioValue &&
                        this.lis[i].types.length > 0
                      ) {
                        return false;
                      } else {
                        if (
                          this.lis[i].selectshow == false &&
                          this.lis[i].checkbox.length < 1 &&
                          this.lis[i].enddateshow == false &&
                          this.lis[i].startdateshow == false &&
                          this.lis[i].agoshow == false &&
                          this.lis[i].optionshow == false &&
                          this.lis[i].types.length < 1 &&
                          this.radio
                        ) {
                          return false;
                        } else {
                          return true;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        if (this.radio == "") {
          return true;
        }
      }
    },
  },
  created() {
    this.explainsect = this.explain.slice(0, 200);
    if (typeof window !== "undefined") {
      document.addEventListener("click", this.clickFn);
    }
    this.radio = this.seedatafrom;
    for (let i = 0; i < this.lis.length; i++) {
      if (this.seedatafrom == this.lis[i].radio) {
        if (this.lis[i].selectshow == true && this.lis[i].checkbox.length < 1) {
          this.selected = this.seedatatoD;
        }
        if (this.lis[i].selectshow == true && this.lis[i].checkbox.length > 0) {
          for (var j = 0; j < this.seedatatoD.length; j++) {
            if (this.seedatatoD[j] == "empty") {
              this.seedatatoD.splice(j, 1);
              this.selected = this.seedatatoD;
              
              for (var z = 0; z < this.checkboxval.length; z++) {
                
                this.lis[i].checkbox[this.checkboxval[z]].checkedselect = true;
              }
            } else {
              this.selected = this.seedatatoD;
            }
          }
        }
        if (
          this.lis[i].startdateshow == true &&
          this.lis[i].enddateshow == false
        ) {
          this.startdate = this.seedatatoD[0];
        }
        if (
          this.lis[i].startdateshow == true &&
          this.lis[i].enddateshow == true
        ) {
          this.startdate = this.seedatatoD[0];
          this.enddate = this.seedatatoD[1];
        }
        if (this.lis[i].agoshow == true && this.lis[i].options.length < 1) {
          let val = this.seedatatoD[0].split(" ")[0];
          this.dayagoone = val;
        }
        if (this.lis[i].agoshow == true && this.lis[i].options.length > 0) {
          this.dayagotwo = this.seedatatoD[0].split(" ")[0];
          let days = this.seedatatoD[0].split(" ");
          let dayago = days.splice(1, days.length - 1).join(" ");
          this.dayagos = dayago;
        }
        if (this.lis[i].optionshow == true && this.lis[i].types.length > 0) {
          this.radioValue = this.seedatatoD[0];
        }
      }
    }
  },
    beforeDestroy() {  // 实例销毁之前对点击事件进行解绑
      // window.removeEventListener('click', this.clickOther);
      document.removeEventListener("click", this.clickFn);
  }
};
</script>

<style lang='scss' scoped >
.seedatatils {
  width: 100%;
  h6 {
    width: 100%;
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #006dcc;
    letter-spacing: 0;
    margin-top: 18px;
    cursor: pointer;
    i {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .seedatalist {
    width: 100%;
    height: 55vh;
    overflow: auto;
    overflow-x: hidden;
    .seep {
      font-family: SourceHanSansCN-Medium;
      font-size: 14px;
      color: #080707;
      letter-spacing: 0;
      font-weight: bolder;
      padding-left: 10px;
      margin-top: 10px;
    }
    .more {
      width: 98%;
      margin-left: 2%;
      .span {
        font-family: MicrosoftYaHei;
        font-size: 12px;
        color: #080707;
      }
      P {
        line-height: 30px;
        color: #006dcc;
        text-align: center;
        cursor: pointer;

        i {
          padding-right: 10px;
          color: #006dcc;
          font-weight: bolder;
          font-family: SourceHanSansCN-Medium;
          font-size: 14px;
        }
        span {
          color: #006dcc;
          font-family: SourceHanSansCN-Medium;
          font-size: 14px;
        }
        span:hover {
          border-bottom: 2px solid #006dcc;
        }
      }
    }
    h5 {
      width: 97%;
      color: #006dcc;
      font-weight: bolder;
      font-family: SourceHanSansCN-Medium;
      font-size: 14px;
      margin-left: 10px;
      cursor: pointer;
      .icon {
        margin-left: 6px;
      }
      span:hover {
        border-bottom: 2px solid #006dcc;
      }
    }
    .radio {
      width: 96%;
      margin-left: 2%;
      .radiop {
        width: 100%;
        display: flex;
        align-items: center;
      }
      .elradio {
        width: 18px;
        height: 18px;
        margin-right: 8px;
        margin-top: 8px;
      }
    }
    .select {
      width: 96%;
      margin-left: 2%;
      min-height: 40px;
      margin-top: 10px;
      border: 1px solid #e3e2e2;

      white-space: pre-wrap;
      span {
        display: inline-block;
        margin: 8px;
        background: #e3e2e2;
        padding: 5px 5px;
        color: #888888;
        i {
          cursor: pointer;
        }
      }
      input {
        outline: none;
        border: none;
        height: 30px;
        margin-left: 5px;
        height: 30px;
        margin-top: 5px;
        color: #080707;
      }
    }
    .but {
      width: 96%;
      margin-left: 2%;
      height: 60px;
      margin-top: 5px;
      border: 1px solid #e3e2e2;
      display: flex;
      align-items: center;
      p {
        line-height: 30px;
        font-family: MicrosoftYaHei;
        font-size: 12px;
        color: #888888;
        width: 100%;
      }
      .butp {
        width: 100%;
      }
    }
    .elcheckbox {
      width: 96%;
      margin-left: 2%;
      margin-top: 10px;
      //     display: flex;
      //     justify-content: space-around;
      //    align-items: center;
      input {
        width: 18px;
        height: 18px;
        width: 10%;
        float: left;
        margin-top: 2px;
      }
      p {
        float: left;
        width: 88%;
      }
    }
  }
  ::v-deep .el-button {
    margin-top: 10px;
    margin-left: 2%;
  }
  ::v-deep .el-date-editor {
    width: 96%;
    margin-left: 2%;
    margin-top: 10px;
  }
  .startend {
    h3 {
      margin-top: 15px;
      margin-left: 2%;
    }
  }
  input {
    outline: none;
  }
  .ago {
    width: 96%;
    margin-left: 2%;
    margin-top: 10px;
    margin-bottom: 10px;

    input {
      width: 66%;
      height: 40px;
      border: 1px solid #ccc;
      margin-right: 10px;
    }
    .el-dropdown-link {
      color: #006dcc;
    }
  }
  .selectsearch {
    width: 96%;
    margin-left: 2%;
    height: 40px;
    border: 1px solid #ccc;
    position: relative;
    margin-top: 10px;
    .el-select {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 10px;
      i {
        padding-right: 10px;
      }
    }
    .el-options {
      height: 307px;
      border: 1px solid #ccc;
      background: #ccc;
      width: 100%;
      position: absolute;
      left: 0;
      top: -317px;
      z-index: 9999;
    }
    .inputop {
      height: 50px;
      width: 96%;
      margin-left: 2%;
      margin-top: 5px;
    }
    .inputul {
      width: 100%;
      height: 250px;
      background: #fff;
      overflow: auto;

      li {
        padding-left: 10px;
        padding-right: 10px;
        margin-top: 5px;
        width: 100%;
        margin-bottom: 5px;

        h4 {
          font-family: MicrosoftYaHei;
          font-size: 14px;
          color: #080707;
          line-height: 32px;
          font-weight: bold;
        }
        span {
          font-family: SourceHanSansCN-Medium;
          font-size: 12px;
          color: #080707;
          line-height: 30px;
        }
      }
      li:hover {
        background: #ccc;
        cursor: pointer;
      }
    }
  }
}
</style>
