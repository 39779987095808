<!--  -->
<template>
  <div class="search">
    <h6 @click="back"><i class="el-icon-arrow-left"></i>Back</h6>
    <p>{{ filterval }}</p>
    <div class="input">
      <el-input
        class="search-text"
        v-model="searchVal"
        placeholder="Search"
        @keyup.enter.native="searchlist"
        clearable
        :suffix-icon="searchVal ? '' : 'el-icon-search'"
      >
      </el-input>
    </div>
    <div class="searchlis">
      <ul v-for="(item, index) in lists" :key="index">
        <h5>{{ item.title }}</h5>
        <li
          v-for="(val, num) in item.lis"
          :key="num"
          @click="searchclick(item, val)"
        >
          {{ val }}
        </li>
      </ul>
    </div>
    <div class="null"></div>
  </div>
</template>

<script>
export default {
  props: {
    filterval: {
      // 过滤类型
      type: String,
      default: "",
    },
  },
  data() {
    return {
      searchVal: "",
      lists: [
        {
          title: "A",
          lis: [
            "Campaign of last booking in meetings tool",
            "First conversion",
          ],
          lisid: "111",
        },
        {
          title: "B",
          lis: ["Last Engagement Date", "Last activity date"],
          lisid: "222",
        },
        {
          title: "C",
          lis: ["Membership notes"],
          lisid: "333",
        },
        {
          title: "D",
          lis: ["Message"],
          lisid: "444",
        },
      ],
    };
  },
  methods: {
    searchlist() {},
    back() {
      this.$emit("searchback", false);
    },
    searchclick(item, val) {
      this.$emit("searchcheck", false, val);
    },
  },
};
</script>

<style lang='scss' scoped >
.search {
  width: 100%;
  h6 {
    width: 100%;
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #006dcc;
    letter-spacing: 0;
    margin-top: 18px;
    cursor: pointer;
    i {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  p {
    font-family: SourceHanSansCN-Medium;
    font-size: 14px;
    color: #080707;
    letter-spacing: 0;
    font-weight: bolder;
    padding-left: 10px;
    margin-top: 10px;
  }
  .input {
    width: 96%;
    margin-left: 2%;
    ::v-deep.el-input__inner {
      width: 100%;
      height: 40px;
    }
  }
  .searchlis {
    margin-top: 70px;
    width: 96%;
    margin-left: 2%;
    height: 330px;
    overflow: auto;
    ul {
      h5 {
        font-family: SourceHanSansCN-Medium;
        font-size: 14px;
        color: #080707;
        letter-spacing: 0;
        font-weight: bold;
        padding-left: 10px;
        margin-top: 10px;
      }
      .input {
        width: 96%;
        margin-left: 2%;
        ::v-deep .el-input__inner{
            width: 100%;
            height: 40px;
        }
      }
      .searchlis {
        margin-top: 70px;
        width: 96%;
        margin-left: 2%;
        height: 330px;
        overflow: auto;
        ul {
          h5 {
            font-family: SourceHanSansCN-Medium;
            font-size: 14px;
            color: #080707;
            letter-spacing: 0;
            font-weight: bold;
            padding-left: 10px;
            margin-bottom: 10px;
          }
          li {
            font-family: MicrosoftYaHei;
            font-size: 12px;
            color: #080707;
            letter-spacing: 0;
            line-height: 32px;
            padding-left: 10px;
          }
          li:hover {
            background: #ccc;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
